// Copyright (C) 2017-2023 Quasi, Inc. All Rights Reserved.

import {createSlice, createAsyncThunk, isAnyOf} from '@reduxjs/toolkit'

import Environment from '../../../services/Environment'
import Company from '../../../services/Company'

export const environmentsList = createAsyncThunk('environments/list', async (params) => {
//  console.log(params)
  const {companyId, ...query} = params
//  console.log('env store', companyId)
  const srv = companyId ? new Company(companyId) : Environment
  const response = await srv.environments({params: query})
//  console.log(response.data)
  return {
    params,
    data: response.data.environments,
    total: response.data.total
  }
})

export const loadEnvironment = createAsyncThunk('environments/load', async id => {
  const response = await new Environment(id).get()
  return response.data
})

export const createEnvironment = createAsyncThunk('environments/create', async (environment, {
  dispatch,
  getState,
  rejectWithValue
}) => {
  try {
    const srv = environment.companyId ? new Company(environment.companyId) : Environment
    const res = environment.companyId ? await srv.createEnvironment(environment) : srv.create(environment)
    await dispatch(environmentsList(getState().environments.params))
    return res.data
  } catch (err) {
    console.error('createEnvironment error:', err.response)
    return rejectWithValue(err.response?.data?.msg)
  }
})

export const updateEnvironment = createAsyncThunk('environments/update', async (environment, {
  dispatch,
  getState,
  rejectWithValue
}) => {
  try {
    const {envId, ...params} = environment
    const res = await new Environment(envId).update(params)
    await dispatch(environmentsList(getState().environments.params))
    //console.log('updateRobot res:', res.data)
    return res.data
  } catch (err) {
    console.error('updateEnvironment error:', err)
    return rejectWithValue(err.response?.data?.msg)
  }
})

export const uploadEnvironmentMap = createAsyncThunk('environments/uploadMap', async (
  {
    envId,
    file,
    onStart,
    onProgress,
    onFinish
  },
  {
    dispatch,
    getState,
    rejectWithValue
  }) => {
    try {
      const res = await new Environment(envId).uploadMap({file, onStart, onProgress, onFinish})      
      return res.data
    } catch (err) { 
      console.error(err)
      return rejectWithValue(err.response?.data?.msg) 
    }
})

export const viewEnvironmentMap = createAsyncThunk('environments/viewMap')

export const downloadEnvironmentMap = createAsyncThunk('environments/downloadMap', async (
  {
    envId,
    onStart,
    onProgress,
    onFinish
  },
  {
    dispatch,
    getState,
    rejectWithValue
  }) => {
    try {
      await new Environment(envId).downloadMap({onStart, onProgress, onFinish})
    } catch (err) {
      console.error(err)
      return rejectWithValue(err.response?.data?.msg)
    }
})

export const environmentsSlice = createSlice({
  name: 'environments',
  initialState: {
    data: [],
    total: 0,
    params: {},
    selectedEnvironment: null,
    loading: false,
    editCreateLoading: false
  },
  reducers: {
    clearSelection: (state) => {
      state.selectedEnvironment = null
    }
  },
  extraReducers: builder => {
    builder
      .addCase(environmentsList.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.total
      })
      .addCase(loadEnvironment.fulfilled, (state, action) => {
        state.selectedEnvironment = action.payload
      })
      .addCase(updateEnvironment.fulfilled, (state, action) => {
        state.selectedEnvironment = action.payload
      })
      .addCase(uploadEnvironmentMap.fulfilled, (state, action) => {
        state.selectedEnvironment = action.payload
      })
      .addMatcher(isAnyOf(
        environmentsList.pending,
        loadEnvironment.pending
      ), (state) => {
        state.loading = true
      })
      .addMatcher(isAnyOf(
        environmentsList.rejected,
        loadEnvironment.rejected,
        environmentsList.fulfilled,
        loadEnvironment.fulfilled
      ), (state) => {
        state.loading = false
      })
      .addMatcher(isAnyOf(
        updateEnvironment.pending,
        createEnvironment.pending
      ), (state) => {
        state.editCreateLoading = true
      })
      .addMatcher(isAnyOf(
        updateEnvironment.fulfilled,
        updateEnvironment.rejected,
        createEnvironment.fulfilled,
        createEnvironment.rejected
      ), (state) => {
        state.editCreateLoading = false
      })
  }
})
export const {
  clearSelection
} = environmentsSlice.actions

export default environmentsSlice.reducer
