// Copyright (C) 2017-2023 Quasi, Inc. All Rights Reserved.

import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import Environment from "../../../services/Environment"
import Robot from "../../../services/Robot"
import ChargingStation from "../../../services/ChargingStation"
import Workspace from "../../../services/Workspace"
import MovementChunk from "../../../services/MovementChunk"

const mockIdsData = [
  {
    _id: "62f3de598a261a709d92c7d2",
    serialNumber: "S45425423524",
    currentTask: 'CurrTask 1'
  },
  {
    _id: "62f3df068a261a709d92c7fc",
    serialNumber: "P23423",
    currentTask: 'CurrTask 2'
  },
  {
    _id: "62f3df818a261a709d92c905",
    name: "Shelf",
    description: 'demo shelf'
  },
  {
    _id: "616ef98a6838aeb009ef5bba",
    name: "Demo WS",
    description: 'table 1 spot 1'
  },
  {
    _id: "63452bfae64f8c95a588eb0a",
    name: "1st floor station",
    description: '105s room'
  },
  {
    _id: "62fb9626bfe141c01ea998fa",
    name: "Genesis station",
    description: 'Genesis company station'
  }
]

export const mapviewList = createAsyncThunk('mapview/list', async (params) => {
  const {...query} = params
  // const response = await Schedule.list({params: query})
  return {
    data: mockIdsData
  }
})

export const loadMap = createAsyncThunk('mapview/map', async (params, { rejectWithValue }) => {
  const { envId, robotId, stationId, wsId, movementId, ...query } = params
  const srv = envId ? new Environment(envId) : robotId ? new Robot(robotId) : stationId ? new ChargingStation(stationId) : wsId ? new Workspace(wsId) : movementId ? new MovementChunk(movementId) : null
  if (srv) {
    const res = await srv.getMapData(query)
    return res.data
  } else {
    rejectWithValue('Please provide object id')
  }
})

export const mapviewSlice = createSlice({
  name: 'mapview',
  initialState: {
    data: null,
    loading: false,
    total: 0
  },
  // reducers: {
  //   clearSelection: (state) => {
  //     state.selectedTaskType = null
  //   }
  // },
  extraReducers: builder => {
    builder
      .addCase(mapviewList.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.loading = false
      })
      .addCase(mapviewList.pending, (state) => {
        state.loading = true
      })
      .addCase(mapviewList.rejected, (state) => {
        state.loading = false
      })
      .addCase(loadMap.pending, (state) => {
        state.loading = true
      })
      .addCase(loadMap.fulfilled, (state, action) => {
        state.loading = false
        state.data = action.payload
      })
      .addCase(loadMap.rejected, (state) => {
        state.loading = false
        state.data = null
      })
  }
})
export const {
} = mapviewSlice.actions

export default mapviewSlice.reducer
