
// Copyright (C) 2017-2023 Quasi, Inc. All Rights Reserved.

import {BaseService} from './index'
import axios from 'axios'
import { fakeApiData } from "../utility/Utils"

export default class Robot extends BaseService {
  static collection = 'robots'

  static robots(props) {
    return this.list(props)
  }
  tasks(props) {
    const {status, ...rest} = props
    const config = this.constructor.constructConfig(rest)
    return axios.get(this.constructUrl('tasks'), config)
  }
  tasksPoints(props) {
    // const { id, ...rest } = props
    // const config = this.constructor.constructConfig(rest)
    // return axios.get(this.constructUrl(['tasksPoints']), config)
    return axios.get(this.constructUrl('tasksPoints'), {params})

  }
  tasksDashPoints(params) {
    const config = this.constructor.constructConfig({params})
    return axios.get(this.constructUrl('tasksDashPoints'), config)
  }

  tasksWeeklyStats(params) {
    const config = this.constructor.constructConfig({params})
    return axios.get(this.constructUrl('tasksWeeklyStats'), config)
  }

  getChargingPoints(params) {
    return axios.get(this.constructUrl('chargingEventsDash'), { params })
  }

  getTimeAllocation(params) {
    return axios.get(this.constructUrl('timeAllocation'), {params})
  }

  getDistancePoints(params) {
    return axios.get(this.constructUrl('distanceDash'), {params})
  }

  getChargingBatteryDash(params) {
    return axios.get(this.constructUrl('chargingEventBatteryDash'), {params})
  }

  taskTemplates(params) {
    const config = this.constructor.constructConfig({params})
    return axios.get(this.constructUrl('tasktemplates'), config)
  }

  chargingEvents(params) {
    return axios.get(this.constructUrl('chargingEvents'), {params})
  }

  movementChunks(params) {
    return axios.get(this.constructUrl('movementChunk'), {params})
  }

  getMapData(params) {
    return axios.get(this.constructUrl('mapData'), {params})
  }

  getProfit(params) {
    return axios.get(this.constructUrl('profit'), {params})
  }
}
