// Copyright (C) 2017-2023 Quasi, Inc. All Rights Reserved.

import {createSlice, createAsyncThunk, isAnyOf} from '@reduxjs/toolkit'

import Robot from '../../../services/Robot'
import Company from '../../../services/Company'
import Environment from "../../../services/Environment"

export const robotsList = createAsyncThunk('robots/list', async params => {
  //console.log(params)
  const {companyId, envId, ...query} = params
  const srv = companyId ? new Company(companyId) : envId ? new Environment(envId) : Robot
  const response = await srv.robots({params: query})
  //console.log(response.data)
  return {
    params,
    data: response.data.robots,
    total: response.data.total
  }
})

export const loadRobot = createAsyncThunk('robots/load', async id => {
  const response = await new Robot(id).get()
  return response.data
})

export const createRobot = createAsyncThunk('robots/create', async (robot, { dispatch, getState, rejectWithValue }) => {
  try {
    const res = await Robot.create(robot)
    await dispatch(robotsList(getState().robots.params))
    return res.data
  } catch (err) {
    console.error('createRobot error:', err.response)
    return rejectWithValue(err.response?.data?.msg)
  }
})

export const updateRobot = createAsyncThunk('robots/update', async (robot, { dispatch, getState, rejectWithValue }) => {
  try {
    const {robotId, ...params} = robot
    const res = await new Robot(robotId).update(params)
    await dispatch(robotsList(getState().robots.params))
    //console.log('updateRobot res:', res.data)
    return res.data
  } catch (err) {
    console.error('updateRobot error:', err.response)
    return rejectWithValue(err.response?.data?.msg)
  }
})

export const getTasksPoints = createAsyncThunk('robots/tasksPoints', async id => {
  const response = await new Robot(id).tasksPoints({ id })
  return response.data
})

export const robotsSlice = createSlice({
  name: 'robots',
  initialState: {
    data: [],
    total: 0,
    params: {},
    selectedRobot: null,
    loading: false,
    selectedLoading: false,
    editCreateLoading: false,
    dashboard: {
      tasks: {
        loading: false,
        data: null
      }
    }
  },
  reducers: {
    clearSelection: (state) => {
      state.selectedRobot = null
    }
  },
  extraReducers: builder => {
    builder
      .addCase(robotsList.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.total
        state.loading = false
      })
      .addCase(robotsList.pending, (state) => {
        state.loading = true
      })
      .addCase(robotsList.rejected, (state) => {
        state.loading = false
      })
      .addCase(loadRobot.fulfilled, (state, action) => {
         state.selectedRobot = action.payload
        state.selectedLoading = false
       })
      .addCase(loadRobot.pending, (state) => {
        state.selectedLoading = true
      })
      .addCase(loadRobot.rejected, (state) => {
        state.selectedLoading = false
      })
      .addCase(updateRobot.fulfilled, (state, action) => {
        //console.log('pdateRobot.fulfilled', action)
        state.selectedRobot = action.payload
      })
      .addCase(getTasksPoints.pending, (state) => {
        state.dashboard.tasks.loading = true
      })
      .addCase(getTasksPoints.fulfilled, (state, action) => {
        state.dashboard.tasks.data = action.payload
        state.dashboard.tasks.loading = false
      })
      .addCase(getTasksPoints.rejected, (state) => {
        state.dashboard.tasks.loading = false
      })
      .addMatcher(isAnyOf(
        updateRobot.pending,
        createRobot.pending
      ), (state) => {
        state.editCreateLoading = true
      })
      .addMatcher(isAnyOf(
        updateRobot.fulfilled,
        updateRobot.rejected,
        createRobot.fulfilled,
        createRobot.rejected
      ), (state) => {
        state.editCreateLoading = false
      })
 }
})

export const {
  clearSelection
} = robotsSlice.actions

export default robotsSlice.reducer
